export default {
  components: {
    Faqs: () => import('~/components/commom/faqs/index'),
    Team: () => import('~/components/commom/team/index'),
    FooterBlock: () => import('~/components/commom/footer/index'),
    HeaderBlock: () => import('~/components/commom/header/index'),
    ButtonBlock: () => import('~/components/commom/button-block/index'),
    HowItWorks: () => import('~/components/layouts/how-it-works/index'),
    CarInsuranceCompanies: () =>
      import('~/components/layouts/car-insurance-companies/index')
  },
  async fetch({ store }) {
    if (store.state.team.members.length === 0) {
      await store.dispatch('team/getTeam')
    }
  },
  data() {
    return {
      companies: [],
      faqs: [
        {
          title: this.$i18n.t(
            'Is it possible to obtain an insurance contract online?'
          ),
          content: this.$i18n.t(
            "Yes it is possible to make a valid contract online and/or by phone. In the same way a contract is concluded in person, the conclusion of a contract online or by phone requires the submission of your insurance company and contract's information."
          )
        },
        {
          title: this.$i18n.t(
            'How do I make my payments if i make an online contract?'
          ),
          content: this.$i18n.t(
            'You can pay online through direct debit, ATM, or credit card. You can make your payments annually, quarterly, or monthly (Depending of the insurance company there may be discounts should you make an anual payment or should you pay through direct debit).'
          )
        },
        {
          title: this.$i18n.t(
            'Who can make contracts? Do I need to be the main driver?'
          ),
          content: this.$i18n.t(
            'All people with Category B(light vehicle) driving license can make an automobile insurance contract. The insured does not need to be the main driver of the vehicle. As you make your contract, you can include other drivers to your insurance.'
          )
        },
        {
          title: this.$i18n.t('When does the insurance come into effect?'),
          content: this.$i18n.t(
            'After signing the contract and the issuance of the policy, the insurance will enter into effect 24 hours after the start date'
          )
        },
        {
          title: this.$i18n.t(
            'In the event of a casualty how should I procceed?'
          ),
          content: this.$i18n.t(
            "Should there be a casualty it's important to contact your insurance company as fast as possible and inform them of your casualty. If you signed your contract through the mediator Compare Seguros certified by the Portuguese Insurance Institute(ISP) with the number 411356511, you should follow your contact with them (211 450 030). This is why it's important to have your contact and insurance policy accessible."
          )
        },
        {
          title: this.$i18n.t('Is there a deadline for the casualty payment?'),
          content: this.$i18n.t(
            'This will depend on the type of event. When the casualty is on vehicle repair, the insurance companies will authorize around two or three days after they have received the favorable deliberation towards repair. In the event of full compensation, insurance companies hold a deadline until 30 days to run the payment, from the moment they receive all relevant documentation'
          )
        },
        {
          title: this.$i18n.t('Can I cancel my insurance?'),
          content: this.$i18n.t(
            "Should you intend to cancel your insurance, you'll have to get in direct contact with the respective insurance company's call center. If you made a contract through the mediator Compare Seguros certified by the Portuguese Insurance Institute(ISP) with the number 411356511, you'll have to get in contact with them."
          )
        },
        {
          title: this.$i18n.t(
            'If a friend of mine drives the vehicle and crashes, does the insurance cover it?'
          ),
          content: this.$i18n.t(
            "As long as he's not the main driver of the car, generally the insurance company will cover it. But, should it be proved that your friend drives the vehicle regularly, the insurance company will not cover it"
          )
        }
      ]
    }
  },
  methods: {
    sendSimulationEvent() {
      this.$gtm.pushEvent({
        event: 'car_middle'
      })
    },
    async fetchCompanies() {
      return await this.$axios.get('/companies/active', {
        params: { productType: 2 }
      })
    }
  },

  beforeMount() {
    this.fetchCompanies().then(({ data }) => {
      this.companies = Object.values(data)
    })
  }
}
